import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 18",
  week: "Semana 3",
  month: "Mar",
  day: "31",
  monthNumber: "03",
  date: "2020-03-31",
  path: "/cronologia/semana-03#dia-31-mar",
};

const Day18 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, hasta el momento se han registrado 94.417
          casos (9.222 más que el día anterior), 8.189 fallecidos (8.7% de los
          casos positivos) y 19.259 curados (20,4% de los casos positivos). El
          total de personas en UCI asciende a 5.607.
        </ModText>
        <ModTwoCols
          src="/images/svg/31_mar_consejo_ministrosl.svg"
          description="nuevo paquete de medidas económicas y sociales"
          small="small"
        >
          El Consejo de Ministros ha aprobado este martes un{" "}
          <strong>nuevo paquete de medidas económicas y sociales</strong>
          con el que se completan y refuerzan las medidas adoptadas en las tres
          últimas semanas por el Gobierno para minimizar y contrarrestar el
          impacto del COVID-19.
        </ModTwoCols>
        <ModText>
          También ha aprobado la{" "}
          <strong>
            distribución a las comunidades autónomas de 300 millones de euros
          </strong>{" "}
          dentro del plan de medidas económicas extraordinarias destinadas a
          mitigar el impacto del virus. El reparto se ha llevado a cabo teniendo
          en cuenta los criterios de porcentaje de población (80%) y número de
          casos (15%) e ingresos en UCI (5%) a fecha 24 de marzo
        </ModText>
        <ModTwoCols
          src="/images/svg/31_mar_hotel.svg"
          description="mejorar la calidad de las empresas y los destinos turísticos"
          small="small"
        >
          El <strong>programa 'Anfitriones Turismo'</strong> impulsado por la
          Secretaría de Estado de Turismo adelanta el inicio de varios cursos de
          la edición formativa de primavera, a fin de paliar las actuales
          circunstancias de confinamiento. El programa está enmarcado en el Plan
          del Turismo Español Horizonte 2020, y pretende{" "}
          <strong>
            mejorar la calidad de las empresas y los destinos turísticos
          </strong>{" "}
          para satisfacer de forma correcta las expectativas del visitante.
        </ModTwoCols>
        <ModAnimation svg="/images/anim/31_mar_transportista.svg"></ModAnimation>
        <ModText>
          La Secretaría de Estado de Comercio del Ministerio de Industria,
          Comercio y Turismo ha elaborado una{" "}
          <strong>
            "Guía de recomendaciones para el desarrollo de la actividad
            comercial a distancia y abastecimiento de zonas rurales sin locales
            comerciales"
          </strong>
          , cuyo objetivo es mejorar la protección de los ciudadanos y los
          trabajadores en la comercialización de productos a distancia en las
          modalidades de comercio 'on-line', telefónico, 'click & collect' y
          'click & car'. Complementa la{" "}
          <strong>'Guía de buenas prácticas del sector comercial'</strong>{" "}
          publicada al inicio de la crisis.
        </ModText>
        <ModTwoCols
          src="/images/svg/31_mar_actividad_judicial.svg"
          description="plan de actuación para agilizar la actividad judicial"
        >
          Asimismo, el Gobierno pondrá en marcha un{" "}
          <strong>plan de actuación para agilizar la actividad judicial</strong>{" "}
          en los juzgados y tribunales del orden social y
          contencioso-administrativo, así como en los juzgados de lo mercantil.
        </ModTwoCols>
        <ModImage
          src="/images/svg/31_mar_consumo_hogar.svg"
          alt="/svg/31_mar_consumo_hogar.svg"
        ></ModImage>
        <ModText>
          Los datos del <strong>"Análisis de consumo en el hogar"</strong>, del
          Ministerio de Agricultura, Pesca y Alimentación, reflejan que el
          volumen de compra de alimentos del 16 al 22 de marzo de 2020, la
          primera semana después de la declaración del estado de alarma, se ha
          moderado respecto a la anterior (donde se registró un repunte del
          29,8% respecto a las compras de alimentos realizadas en esa misma
          semana del año anterior).
        </ModText>
        <ModTwoCols
          src="/images/svg/31_mar_16_hospitales.svg"
          alt="dieciséis hospitales de campaña por todo el territorio nacional"
        >
          Las <strong>Fuerzas Armadas</strong> han colaborado en la puesta en
          marcha de{" "}
          <strong>
            dieciséis hospitales de campaña por todo el territorio nacional
          </strong>
          , con el objetivo de ofrecer una respuesta rápida a las necesidades de
          ampliación de capacidades sanitarias y número de camas hospitalarias.
        </ModTwoCols>
        <ModText>
          El{" "}
          <strong>
            Ministerio de Sanidad y el Consejo General de Colegios Oficiales de
            Psicólogos
          </strong>{" "}
          (COP) de España han puesto en marcha un{" "}
          <strong>teléfono de atención psicológica</strong> para prestar apoyo
          asistencial a las personas con dificultades derivadas de la COVID-19.
        </ModText>
        <ModImage
          src="/images/svg/31_mar_3_img_horizontal.svg"
          alt="teléfono de atención psicológica"
        />
        <ModTwoCols
          src="/images/svg/31_mar_retorno_viajeros.svg"
          alt="mejorar la calidad de las empresas y los destinos turísticos"
        >
          El{" "}
          <strong>
            Ministerio de Asuntos Exteriores, Unión Europea y Cooperación
          </strong>{" "}
          ha facilitado el retorno a España de un total de 18.000 viajeros que
          fueron sorprendidos por la pandemia de la COVID-19 en otros países. La
          cifra supone una media de más de mil turistas diarios desde que se
          decretara el estado de alarma.
        </ModTwoCols>
        <ModText>
          La{" "}
          <strong>Dirección General de Seguridad Jurídica y Fe Pública</strong>{" "}
          ha garantizado que los Registros de la Propiedad, Mercantiles,de
          Bienes Muebles y el Registro Concursal, así como las notarías,
          seguirán prestando adecuadamente los servicios mínimos básicos para el
          mantenimiento y desarrollo imprescindible de la actividad económica,
          societaria y financiera durante el estado de alarma.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/presidencia/Paginas/2020/31032020_medidascovid.aspx"
            name="Nuevo paquete de medidas económicas y sociales"
          />
          <ReferenceRow
            link="https://www.mincotur.gob.es/es-es/COVID-19/Documents/guia_para_el_desarrollo_de_la_actividad_comercial_a_distancia_y_en_zonas_rurales.pdf"
            name="Guía de recomendaciones para el desarrollo de la actividad comercial a distancia y abastecimiento de zonas rurales sin locales comerciales"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/industria/Documents/2020/190320-BuenasPracticasSectorComercial.pdf"
            name="Guía de buenas prácticas del sector comercial"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/agricultura/Paginas/2020/310320compra-alimentos.aspx"
            name="Análisis de consumo del hogar"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day18;
